<style lang="less" scoped>

    .successMain p{
        text-align: center!important;
    }
    .successMain b{
        text-align: center!important;
    }

    .htopImg {
        width: 100%;
        margin: auto;
        display: block;
        height: 33vw;
    }
    .width600 {
        width: 900px;
        margin: auto;
    }
    .leftTopLogo {
        width: 13%;
        height: 130px;
    }
    .textBox {
    width: 60%;
    margin: auto;
    height: 205px;
    padding: 10px;
    border: solid #CCC 1px;
    overflow: auto;
}
    .twoDot {
        width: 5%;flex-direction: column;
        div {
            width: 1px;
            height: 1px;
            border: solid #CCC 2px;
            margin: 10px;
        }
    }
    .borderT {
        border: solid #92DEF4 1px;
        width: 75%;
        padding: 1em;
        font-size: 18px;
        color: #333;
    }
    .qMain {
        border: solid #92DEF4 1px;
        padding: 2em;
    }
    .qTabWrap {
        >div {
            border: solid #92DEF4 1px;
            width: 28%;
            padding: 0.2em 0;
            text-align:center;
            cursor: pointer;
            user-select: none;
        }
        >div:hover {
            opacity: 0.8 !important;;
        }
    }
    .tAct {
        background: #00B1E6 !important;color: #FFF !important;
        
    }
    .qtyWrap {
        width: 100%;
        margin:0% auto;
        .sub {
            width: 15px;
            height: 15px;
            cursor: pointer;
            position: relative;
            &::after {
                display: block;
                content:"";
                width: 15px;
                height: 3px;
                top: 6px;
                background: #333;
                position: absolute;;left:0;
            }
        }
        input {
            width: 60% !important;
            border: solid #92DEF4 1px;
            text-align:center;
        }
        .add {
            position: relative;
            width: 15px;
            height: 15px;
            cursor: pointer;
            &::after {
                display: block;
                content:"";
                width: 15px;
                height: 3px;
                top: 6px;
                background: #333;
                position: absolute;;left:0;
            }
            &::before {
                display: block;
                content:"";
                width: 3px;
                height: 15px;
                left: 6px;
                background: #333;
                position: absolute;
                top:0;
            }
        }
    }
    .wid100 {
        width: 60%;
        margin: 0 auto 1em;
        input[type = text],input[type = date],input[type = number],textarea {
            width: 100%;
        }
    }
    .qformItem {
        padding-bottom: 0.3rem;
        white-space: nowrap;
    }
    
    .qsubBtn {
        width: 55%;
        background: #383991;
        color: #FFF;
        margin: auto;
        padding: 0.4em 0;
        text-align:center;
        cursor: pointer;
        user-select: none;
    }
    .successModal {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,0.5);
        left: 0;
        top: 0;
        z-index:1000;
        animation: a 0.3s;
        .successMain {
            margin: 1em;
            padding: 1em;
            border: solid 1px #383991;
            width: 66%;
            margin: auto;
        }
        p {
            text-align: center;
        }
    }
    .mar1 {
        margin: 0.5em;
    }
    @keyframes a {
        from {
            opacity: 0
        }
        to{
            opacity:1
        }
    }
    .centerM {
        position:fixed;
        left: 50%;
        top: 50%;
        padding: 0.5rem 1rem;
        background: #FFF;
        width:40%;
        transform: translate(-50%,-50%);
        border-radius: 5px;
        >div {
            border: solid #10103d 2px;
        }
    }
    .orderbtnWrap {
        >div {
            width: 30%;
            padding: 0.5rem 0;
            color: #FFF;
            text-align:center;
            cursor: pointer;
            user-select: none;
            &:hover {
                opacity: 0.9;
            }
        }
        >div:first-child {
            background: #131D72;
        }
        >div:last-child {
            margin-left: 5%;
            background:#4FA079;
        }
    }
    .checLabel {
        padding: 10px 0 5px;
    }
</style>

<template>
    <div>
        <img class="htopImg vk-bg3"  alt="">
        <section style="background:#FAFAFA;">
            <div class="container" id="padding-top-20">
                <div class="row justify-content-center">
                    <div class="col-md-5">
                        <div class="styled-heading">
                            <h3>{{$t('quictB')}}</h3>
                        </div>
                    </div>
                </div>

                <div class="width600">
                    <div class="sb">
                        <div class="leftTopLogo">
                            <img src="@/assets/images/user.png" style="width: 100%;height: 100%;" />
                        </div>
                        <div class="twoDot ju al">
                            <div></div>
                            <div></div>
                        </div>
                        <div class="borderT ju al">
                            {{$t('quictBMsg')}}
                            
                        </div>
                    </div>
                    <br>
                    <div class="qMain">
                        <!-- <div class="qTabWrap ju al">
                            <div :class="{ tAct:service==1 }" @click="service=1">船長</div>
                            <div :class="{ tAct:service==2 }" @click="service=2">水手</div>
                        </div> -->
                        <!-- <div class="sb qtyWrap al">
                            <div class="sub" @click="addSub(true)"></div>
                            <input type="number" v-model="qty"  />
                            <div class="add" @click="addSub(false)"></div>
                        </div> -->

                        <form class="ju quickForm">
                            <div style="width: 50%">
                                
                                
                                <div class=" wid100">
                                    <div class="qformItem al">{{$t('bookingDate')}}:</div>
                                    <input type="date" :min="min" class="qInp" v-model="booking_date" :placeholder="$t('bookingDate')" />
                                </div>

                                <div class="wid100">
                                    <div class="qformItem al">{{$t('bookingTime')}}:</div>
                                    <!-- <input type="time" class="qInp" v-model="booking_time" :placeholder="$t('bookingDate')" /> -->
                                    <div class="qInp sb">
                                        <select style="width: 48%;" v-model="hours">
                                            <option v-for="(item,i) in hoursList" :key="i" :value="item">{{item}}</option>
                                        </select>
                                        <select style="width: 48%;" v-model="minute">
                                            <option value="00">00</option>
                                            <option value="30">30</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="wid100">
                                    <div class="qformItem al">{{$t('phone')}}:</div>
                                    <div class="ju">
                                        <select v-model="pre" style="width: 30%;margin-right: 5px;">
                                            <option value="+852 ">852</option>
                                            <option value="+853 ">853</option>
                                            <option value="+86 ">86</option>
                                        </select>
                                        <input type="number" class="qInp" v-model="phone" :placeholder="$t('phone')" />
                                    </div>
                                    
                                </div>
                                <div class=" wid100">
                                    <div class="qformItem al">{{$t('email')}}:</div>
                                    <input type="text" class="qInp" v-model="email" :placeholder="$t('enterEmail')" />
                                </div>

                                <div class="wid100">
                                    <div class="qformItem al">{{$t("TheEngineNumber")}}:</div>
                                    <input type="number" min="1" class="qInp" v-model="engine_qty" :placeholder="$t('TheEngineNumber')" />
                                </div>
                                <div class="wid100">
                                    <div class="qformItem al">{{$t('shippingName')}}:</div>
                                    <input type="text" class="qInp" v-model="yacht_name" :placeholder="$t('shippingName')" />
                                </div>
                                <div class="wid100">
                                    <div class="qformItem al">{{$t('shippingLength')}} ({{$t('no15')}}):</div>
                                    <input type="number" min="1" max="15" class="qInp" v-model="length" :placeholder="$t('shippingLength')" />
                                </div>

                                <div class="wid100">
                                    <div class="qformItem al">{{$t("shippingLocation")}}:</div>
                                    <input type="text" class="qInp" v-model="address" :placeholder="$t('shippingLocation')" />
                                </div>

                                <div class="wid100">
                                    <div class="qformItem al">{{$t("remark")}}:</div>
                                    <textarea class="qInp" rows="4" v-model="remark" :placeholder="$t('remark')" />
                                </div>
                            </div>







                            <div style="width: 50%" >
                                
                                <div >
                                    <div class="wid100">
                                        <div class="qformItem al">{{$t('captainNum')}}:</div>
                                        <div class="sb qtyWrap al">
                                            <div class="sub" @click="addSub(true,'qty')"></div>
                                            <input type="number" v-model="qty"  />
                                            <div class="add" @click="addSub(false,'qty')"></div>
                                        </div>
                                    </div>

                                    <div class="wid100">
                                        <div class="qformItem al">{{$t('sailorNum')}}:</div>
                                        <div class="sb qtyWrap al">
                                            <div class="sub" @click="addSub(true,'qty2')"></div>
                                            <input type="number" v-model="qty2"  />
                                            <div class="add" @click="addSub(false,'qty2')"></div>
                                        </div>
                                    </div>

                                    <!--overtime!-->
                                    <div class="wid100">
                                        <div class="qformItem al">{{$t('overtime')}}:</div>
                                        <div class="sb qtyWrap al">
                                            <div class="sub" @click="addSub(true,'overtime')"></div>
                                            <input type="number" v-model="overtime" min="0" max="3" />
                                            <div class="add" @click="addSub(false,'overtime')"></div>
                                        </div>
                                    </div>
                                </div><br/>
                                <div class="sb wid100">
                                    <div class="al" style="color:#383991">{{$t('total')}}:</div>
                                    <div class="sb al">
                                    
                                        <div class=" al"><h6 style="color:#383991">HK$ {{ (1600 * qty + (overtime * 450)) + (1100 * qty2 + (overtime * 300))}}</h6></div>
                                        
                                    </div>
                                </div>
                                <div class="wid100">
                                    <small style="color:darkred;">
                                        {{$t('ifOverTime')}}
                                        
                                        <br/>
                                        {{$t('payMore')}}
                                        
                                    </small>
                                </div>
                                
                                <div class="textBox">
                                    <div ><b >{{$t("termtitle")}}</b><br/>
                                        <ol><br/>
                                            <li>
                                                <b>{{$t("term1Title")}}</b><br/>
                                            {{$t("term1")}}<br/>
                                            {{$t("term1two")}}<br/>
                                            {{$t("term1three")}}<br/>
                                            {{$t("term1four")}}<br/>
                                            {{$t("term1five")}}
                                            </li><br/>
                                            <li> 
                                                <b>{{$t("term2Title")}}</b><br/>
                                                {{$t("term2")}} <br/>
                                                {{$t("term2two")}}<br/>
                                                {{$t("term2three")}}
                                            </li> <br/>
                                            <li>
                                                <b>{{$t("term3Title")}}</b><br/>
                                                {{$t("term3")}}
                                                {{$t("term3two")}}<br/>
                                                {{$t("term3three")}}
                                            </li><br/>
                                            <li>
                                                <b> {{$t("term4Title")}}</b><br/>
                                                {{$t("term4")}}
                                                {{$t("term4two")}}<br/>
                                            {{$t("term4three")}}<br/>
                                            {{$t("term4four")}}<br/>
                                            {{$t("term4five")}}
                                            </li> 
                                        </ol>    
                                    </div>
                                </div>
                                <div class="wid100">
                                    <label for="check" class="al checLabel">
                                        <input type="checkbox" id="check" v-model="check">
                                        <span style="margin-left:10px;" class="smallText">{{$t('iAgree')}}</span>
                                    </label>
                                </div>
                                <div class="qsubBtn" @click="sub" v-if="!load">{{$t('bookNow')}}</div>
                                <div class="qsubBtn" @click="sub" v-else>{{$t('ordering')}}</div>
                            </div>
                            

                            
                            
                        </form>
                        <br/>
                        

                    </div>
                </div>


            </div>
        </section>
        <br>
        <br>
        <br>
        <div v-if="success" class="successModal" >
            <div class="centerM" @click.stop="">
                <div >
                    <br>
                    <div class="ju"><img src="@/assets/images/logo.png" width="90" ></div>
                    <div class="ju" style="padding: 0.3rem 0;">{{$t("thankOrder")}}</div>
                    <div class="successMain">
                        <p>{{$t('pleasePay')}}</p>
                        <p> HK$ {{ (1600 * qty) + (1100 * qty2)}}</p>
                        <div class="ju">
                            <img src="@/assets/images/acc.png" style="width: 100%;">
                        </div>
                        <div class="ju twoNum" style="text-align:center;">
                            <p >143141562001</p>
                            <p >161224431</p>
                        </div>
                        <br>
                        
                        
                        <p>{{$t("payMsg")}}</p>
                        <p>+852 6099 4051</p>
                        <!--whatsapp FPS hsbc!-->
                    </div> 
                    <div class="mar1">
                        <p>{{$t('orderNum')}}:</p>
                        <p>{{order_num}}</p> 
                    </div>
                    
                    <div class="ju orderbtnWrap">
                        <div class="op" @click="changePath('/')">{{$t("backToHome")}}</div>
                        <div class="op" @click="changePath('/userPanel')">{{$t("myOrders")}}</div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            service: 1,
            qty: 0,
            qty2: 0,
            booking_date:'',
            engine_qty:"",
            yacht_name:"",
            address:"",
            phone:"",
            remark: "",
            load: false,
            success: false,
            order_num:"",
            booking_time:"",
            overtime:0,
            length: "",
            email:"",
            check: false,
            hoursList:['10',"11","12","13","14","15","16","17",'18'],
            hours: "10",
            minute:"00",
            min: "",
            pre: "+852 ",
        }
    },
    computed: {
        login () { return this.$store.state.login }
    },
    created () {
        // for (let i=0;i<24;i++) {
        //     this.hoursList.push(i<10 ? "0" + i : i + "")
        // }
        let D = new Date( Date.now()+ (86400000*3) ) //只能夠選擇三天後
        let y = D.getFullYear()
        let m = D.getMonth() + 1
        m = m < 10 ? "0" + m : m
        let d = D.getDate()
        d = d < 10 ? "0" + d : d
        this.min = y + "-" + m + "-" + d
    },
    methods: {
        sub () {
            if (!this.login) {
                this.$router.push("/register")
                return false
            }
            if (Number(this.qty) + Number(this.qty2) < 1) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("atLeast"),// "請至少選擇一個船長或水手",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (!this.booking_date) {
                this.$Swal.fire({
                    icon:"warning",
                    text:this.$t("chooseBookingDate"),// "請選擇預定時間",
                    confirmButtonText: this.$t('confirm')
                })
            } 
            else if (!this.phone) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("enterPhone"),//"請輸入聯絡電話",
                    confirmButtonText:this.$t('confirm')
                })
            } 
            else if ((this.engine_qty === 0)) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("engNo"),// "主機數量不得小於1",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (this.engine_qty === "0") {
                this.$Swal.fire({
                    icon:"warning",
                    text:  this.$t("engNo"),//"主機數量不得小於1",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if ((Number(this.engine_qty) < 1)) {
                this.$Swal.fire({
                    icon:"warning",
                    text:  this.$t("engNo"),//"主機數量不得小於1",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (!this.email) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("noEmail"),//"請輸入email",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (!this.email.includes("@")) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("emailRe"),//"email格式有误",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (!this.yacht_name) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("shipId"),//"請輸入船隻編號",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (!this.length) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("noLength"),//"請輸入船隻長度",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (Number(this.length) > 15 ) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("no15m"),//"船隻長度不可超过15米",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (!this.address) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("upLoca"),//"請輸入上船地點",
                    confirmButtonText: this.$t('confirm')
                })
            } 
            else if (!this.check) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("agreeA"),//"請同意条款",
                    confirmButtonText: this.$t('confirm')
                })
            } 
            
            else {
                this.load = true
                let data = {
                    "yacht_name":this.yacht_name,
                    "engine_qty":this.engine_qty ? Number(this.engine_qty) : 1,
                    "booking_date":this.booking_date + ' ' + this.hours + ":" + this.minute,
                    "address": this.address,
                    "captain":this.qty > 0 ? "船長" + this.qty + '人' : '',
                    "sailor":this.qty2 > 0 ? "水手" + this.qty2 + '人' : '',
                    "email": this.email,
                    "overtime" : this.overtime,
                    phone: this.pre + this.phone,
                    remark: this.remark,
                    type: 2,
                    yacht_len: this.length,
                }

                this.$http.post("order",data).then(res => {
                    console.log(res)
                    this.load = false
                    if (res.data.code == 200) {
                        this.success = true
                        this.order_num = res.data.order_num
                    } else {
                        this.$Swal.fire({
                            icon:"warning",
                            text: res.data.message,
                            confirmButtonText: this.$t('confirm')
                        })
                    }
                }).catch(e => {
                    this.load = false
                    console.log(e)
                    this.$Swal.fire({
                        icon:"error",
                        text: "error",
                        confirmButtonText: this.$t('confirm')
                    })
                })
            }
            
        },
        addSub (boo,key) {
            if (boo) {
                //點擊減號
                this[key]--
            } else {
                //點擊加號
                this[key]++
            }
            if (this[key] <=0) {
                this[key] = 0
            }
        },
        changePath (path) {
            this.$router.push(path)
        },
    }
}
</script>

